import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ErrorNotFoundPage } from '../components/ErrorNotFoundPage/ErrorNotFoundPage';
import { ContentfulPage } from '../types/types';

const NotFound: React.FC<NotFoundPageProps> = ({ data }) => {
  return (
    <Layout floatingHeader>
      <Metadata title={data.page.name} />
      <ErrorNotFoundPage data={data} />
    </Layout>
  );
};

export const query = graphql`
  query NotFoundPage($locale: String) {
    page: contentfulPage(slug: { eq: "404" }, node_locale: { eq: $locale }) {
      name
      hero {
        cta {
          raw
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export interface NotFoundPageData {
  page: ContentfulPage;
}

interface NotFoundPageProps {
  data: NotFoundPageData;
}

export default NotFound;
