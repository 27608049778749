export class VisibilityManager {
  //#region Singleton distribution
  private static instance: VisibilityManager;

  public static getInstance(): VisibilityManager {
    if (!VisibilityManager.instance) {
      VisibilityManager.instance = new VisibilityManager();
    }

    return VisibilityManager.instance;
  }
  //#endregion

  //#region Private properties
  private globalObserver: IntersectionObserver = typeof window === 'undefined' ? null : new IntersectionObserver(this.onIntersection.bind(this), {
    rootMargin: '-50px 0px'
  });

  private observees: Map<Symbol, IntersectionObservee> = new Map();
  //#endregion

  //#region Lifecycles
  private constructor() { }
  //#endregion

  //#region Public methods
  public subscribe(el: HTMLElement, callback: IntersectionEntryCallback, observerInit?: IntersectionObserverInit): Symbol {
    const symbol: Symbol = Symbol();
    const observer: IntersectionObserver = observerInit ? new IntersectionObserver(this.onIntersection.bind(this), observerInit) : this.globalObserver;

    this.observees.set(symbol, {
      subject: el,
      action: callback,
      observer
    });

    observer.observe(el);

    return symbol;
  }

  public unsubscribe(symbol: Symbol): void {
    if (this.observees.has(symbol)) {
      const { observer, subject } = this.observees.get(symbol)

      observer.unobserve(subject);
      this.observees.delete(symbol);
    }
  }
  //#endregion

  //#region Private methods
  private onIntersection(entries: IntersectionObserverEntry[]): void {
    for (const entry of entries) {
      this.observees.forEach((observee) => {
        if (entry.target === observee.subject) {
          observee.action(entry);
        }
      });
    }
  }
  //#endregion
}

type IntersectionEntryCallback = (entry: IntersectionObserverEntry) => void;

interface IntersectionObservee {
  subject: Element;
  action(entry: IntersectionObserverEntry): void;
  observer: IntersectionObserver;
}