import styled from 'styled-components';
import { StyledTextModule } from '../TextModule/TextModule.style';
import { pxtorem } from '../../utils/tools';
import { StyledLink } from '../Generic/Cta/Cta.style';

export const StyledErrorPage = styled.div`
  ${StyledTextModule} {
    padding-top: ${pxtorem(80)};

    .text__content {
      margin-bottom: ${pxtorem(32)};
    }

    ${StyledLink} {
      width: auto;
    }

    h1 {
      margin-inline: auto;
      max-width: 22ch;
      font-size: clamp(${pxtorem(35)}, 4.86667vw, ${pxtorem(60)});
      line-height: 100%;
      em {
        font-size: clamp(${pxtorem(31)}, 4.1667vw, ${pxtorem(52)});
        line-height: 115%;
      }
    }
  }
`;
