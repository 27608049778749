import React from 'react';
import { NotFoundPageData } from '../../pages/404';
import { Section } from '../../styles/Section.styled';
import { Wrapper } from '../../styles/Wrapper.styled';
import { TextModule } from '../TextModule/TextModule';
import { StyledErrorPage } from './ErrorNotFoundPage.style';

export const ErrorNotFoundPage: React.FC<ErrorNotFoundPageProps> = ({
  data,
}) => {
  const { hero } = data.page;
  return (
    <StyledErrorPage>
      <Section bg="lime">
        <Wrapper width="wide">
          <TextModule section={hero} centered showShapes ctaVariant="primary" />
        </Wrapper>
      </Section>
    </StyledErrorPage>
  );
};

interface ErrorNotFoundPageProps {
  data: NotFoundPageData;
}
